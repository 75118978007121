import React, { useMemo } from "react";
import "./UpsertCalendarRowModalMilestoneGroup.scss";
import { Button, CheckboxSimple } from "common-components";
import { PlusIcon } from "@gitlab-rtsensing/component-library";
import produce from "immer";
import { QueryStatus, useQuery } from "@tanstack/react-query";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import UpsertCalendarRowMilestone from "../milestone/UpsertCalendarRowModalMilestone";
import EmptyContainer from "common-components/empty-container/EmptyContainer";
import { CalendarMilestone } from "api/calendar/calendar-common";
import { getMilestoneCalendarKeys } from "api";
import dayjs from "dayjs";

const namespace = "rts-pa-upsert-calendar-row-modal-milestone-group";

type Props<T extends AutoUpdaterType> = {
  addButton: boolean;
  status?: QueryStatus;
  autoUpdater: AutoUpdater<T>;
};

const UpsertCalendarRowModalMilestoneGroup = <T extends AutoUpdaterType>(
  props: Props<T>
) => {
  const { value: milestones, onChange } = useAutoUpdater<
    CalendarMilestone[],
    T
  >(props.autoUpdater);

  const { data: apiCalendarKeys } = useQuery(
    ["calendar-keys"],
    getMilestoneCalendarKeys
  );

  const onAddMilestone = () => {
    if (!apiCalendarKeys?.keys) {
      return;
    }

    const defaultKeyId = apiCalendarKeys.keys[0].id;

    const newMilestones = produce(milestones, draftState => {
      const milestone: CalendarMilestone = {
        id: 0,
        milestoneVersionId: 0,
        name: "",
        text: "",
        type: "manual",
        milestoneLinkId: null,
        milestoneCalendarKeyId: defaultKeyId,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: null,
        isVisible: true,
        isVisibleToOfficers: false,
        status: null,
      };

      draftState.push(milestone);
    });

    onChange(newMilestones);
  };

  const areAllChecked = useMemo(() => {
    const milestones = props.autoUpdater.propExpression(
      props.autoUpdater.item
    ) as CalendarMilestone[];

    let isAnyChecked = false;
    let isAnyUnchecked = false;

    for (const milestone of milestones) {
      if (milestone.isVisible) {
        isAnyChecked = true;
      } else if (!milestone.isVisible) {
        isAnyUnchecked = true;
      }

      if (isAnyChecked && isAnyUnchecked) {
        return "half";
      }
    }

    return isAnyChecked; // default return is all checked
  }, [props.autoUpdater.item]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCheckAllClick = () => {
    if (areAllChecked === "half" || !areAllChecked) {
      // check all milestones
      const newMilestones = produce(milestones, draftState => {
        for (const milestone of draftState) {
          milestone.isVisible = true;
        }
      });

      onChange(newMilestones);
    } else if (areAllChecked) {
      // uncheck all milestones
      const newMilestones = produce(milestones, draftState => {
        for (const milestone of draftState) {
          milestone.isVisible = false;
        }
      });

      onChange(newMilestones);
    }
  };

  return (
    <div className={namespace}>
      <CheckboxSimple
        checked={areAllChecked}
        onChange={onCheckAllClick}
        className={`${namespace}-show-all-milestone-header`}
        label="SHOW ALL MILESTONES"
      />
      {milestones.length ? (
        <>
          {milestones.map((_, i) => (
            <UpsertCalendarRowMilestone
              key={i}
              autoUpdater={{
                item: props.autoUpdater.item,
                setItem: props.autoUpdater.setItem,
                propExpression: ri =>
                  (props.autoUpdater.propExpression(ri) as CalendarMilestone[])[
                    i
                  ]
              }}
              onDeleteClick={
                !props.addButton
                  ? undefined
                  : () => {
                      const newItem = produce(
                        props.autoUpdater.item,
                        draftState => {
                          const list = props.autoUpdater.propExpression(
                            draftState as T
                          ) as CalendarMilestone[];

                          list.splice(i, 1);
                        }
                      );

                      props.autoUpdater.setItem(newItem);
                    }
              }
            />
          ))}
        </>
      ) : (
        <EmptyContainer status={props.status || "success"} />
      )}

      {props.addButton && (
        <Button
          type="link"
          text="Add Milestone"
          onClick={onAddMilestone}
          icon={<PlusIcon height={14} width={14} fill="#0063C3" />}
        />
      )}
    </div>
  );
};

export default UpsertCalendarRowModalMilestoneGroup;

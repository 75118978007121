import { AdvancedTooltipValidator, PipelineActivityStatusValidator } from "api/common";
import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";

export const CalendarMilestoneTypeValidator = Union(
  Literal("manual"),
  Literal("automated")
);

export type CalendarMilestoneType = Static<
  typeof CalendarMilestoneTypeValidator
>;

export const CalendarMilestoneValidator = Record({
  id: Number,
  milestoneVersionId: Number,
  name: String, // title
  text: String, // additional info
  type: CalendarMilestoneTypeValidator,
  milestoneLinkId: String.nullable(),
  milestoneCalendarKeyId: Number,
  startDate: String,
  endDate: String.nullable(),
  isVisible: Boolean.nullable(),
  isVisibleToOfficers: Boolean,
  tooltip: AdvancedTooltipValidator.nullable().optional(),
  status: PipelineActivityStatusValidator.nullable()
});

export type CalendarMilestone = Static<typeof CalendarMilestoneValidator>;

export const CalendarMilestonesValidator = Array(CalendarMilestoneValidator);

export type CalendarMilestones = Static<typeof CalendarMilestonesValidator>;
